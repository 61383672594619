import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 mb-3" }
const _hoisted_3 = { class: "card mb-5 mb-xl-10" }
const _hoisted_4 = { class: "card-header border-0 cursor-pointer" }
const _hoisted_5 = { class: "card-title m-0" }
const _hoisted_6 = { class: "fw-bolder m-0" }
const _hoisted_7 = { class: "card-body border-top p-9" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "col-6" }
const _hoisted_10 = { class: "row mb-6" }
const _hoisted_11 = { class: "col-lg-6 col-form-label fw-bold fs-6" }
const _hoisted_12 = { class: "col-lg-6 col-form-label fw-bold fs-6 text-primary" }
const _hoisted_13 = { class: "row mb-6" }
const _hoisted_14 = { class: "col-lg-6 col-form-label fw-bold fs-6" }
const _hoisted_15 = { class: "col-lg-6 col-form-label fw-bold fs-6 text-primary" }
const _hoisted_16 = { class: "row mb-6" }
const _hoisted_17 = { class: "col-lg-6 col-form-label fw-bold fs-6" }
const _hoisted_18 = { class: "col-lg-6 col-form-label fw-bold fs-6 text-primary" }
const _hoisted_19 = { class: "row mb-6" }
const _hoisted_20 = { class: "col-lg-6 col-form-label fw-bold fs-6" }
const _hoisted_21 = { class: "col-lg-6 col-form-label fw-bold fs-6 text-primary" }
const _hoisted_22 = { class: "col-6" }
const _hoisted_23 = { class: "row mb-6" }
const _hoisted_24 = { class: "col-lg-6 col-form-label fw-bold fs-6" }
const _hoisted_25 = { class: "col-lg-6 col-form-label fw-bold fs-6 text-primary" }
const _hoisted_26 = { class: "row mb-6" }
const _hoisted_27 = { class: "col-lg-6 col-form-label fw-bold fs-6" }
const _hoisted_28 = { class: "col-lg-6 col-form-label fw-bold fs-6 text-primary" }
const _hoisted_29 = { class: "row mb-6" }
const _hoisted_30 = { class: "col-lg-6 col-form-label fw-bold fs-6" }
const _hoisted_31 = { class: "col-lg-6 col-form-label fw-bold fs-6 text-primary" }
const _hoisted_32 = { class: "row" }
const _hoisted_33 = { class: "col-12 mb-3" }
const _hoisted_34 = { class: "card mb-5 mb-xl-10" }
const _hoisted_35 = { class: "card-header border-0 cursor-pointer" }
const _hoisted_36 = { class: "card-title m-0" }
const _hoisted_37 = { class: "fw-bolder m-0" }
const _hoisted_38 = { class: "card-body border-top p-9" }
const _hoisted_39 = { class: "row mb-6" }
const _hoisted_40 = { class: "col-lg-4 col-form-label fw-bold fs-6" }
const _hoisted_41 = { class: "col-lg-8" }
const _hoisted_42 = { class: "row" }
const _hoisted_43 = { class: "col-lg-12 fv-row" }
const _hoisted_44 = { class: "row mb-6" }
const _hoisted_45 = { class: "col-lg-4 col-form-label fw-bold fs-6" }
const _hoisted_46 = { class: "col-lg-8" }
const _hoisted_47 = { class: "row" }
const _hoisted_48 = { class: "col-lg-12 fv-row" }
const _hoisted_49 = { class: "card-footer d-flex justify-content-end py-6 px-9" }
const _hoisted_50 = { class: "indicator-label" }
const _hoisted_51 = { class: "indicator-progress" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.$t("pages.userDetails.title")), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t("pages.userDetails.name")), 1),
                  _createElementVNode("div", _hoisted_12, _toDisplayString(this.user.userprofile.name), 1)
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.$t("pages.userDetails.firstname")), 1),
                  _createElementVNode("div", _hoisted_15, _toDisplayString(this.user.userprofile.firstname), 1)
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.$t("pages.userDetails.email")), 1),
                  _createElementVNode("div", _hoisted_18, _toDisplayString(this.user.email), 1)
                ]),
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("label", _hoisted_20, _toDisplayString(_ctx.$t("pages.userDetails.company")), 1),
                  _createElementVNode("div", _hoisted_21, _toDisplayString(this.user.userprofile.companies), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_22, [
                _createElementVNode("div", _hoisted_23, [
                  _createElementVNode("label", _hoisted_24, _toDisplayString(_ctx.$t("pages.userDetails.isActive")), 1),
                  _createElementVNode("div", _hoisted_25, _toDisplayString(this.user.isActive), 1)
                ]),
                _createElementVNode("div", _hoisted_26, [
                  _createElementVNode("label", _hoisted_27, _toDisplayString(_ctx.$t("pages.userDetails.userType")), 1),
                  _createElementVNode("div", _hoisted_28, _toDisplayString(this.user.userprofile.userType), 1)
                ]),
                _createElementVNode("div", _hoisted_29, [
                  _createElementVNode("label", _hoisted_30, _toDisplayString(_ctx.$t("pages.userDetails.status")), 1),
                  _createElementVNode("div", _hoisted_31, _toDisplayString(this.user.status), 1)
                ])
              ])
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_32, [
      _createElementVNode("div", _hoisted_33, [
        _createElementVNode("div", _hoisted_34, [
          _createElementVNode("div", _hoisted_35, [
            _createElementVNode("div", _hoisted_36, [
              _createElementVNode("h3", _hoisted_37, _toDisplayString(_ctx.$t("pages.userDetails.titleBis")), 1)
            ])
          ]),
          _createVNode(_component_Form, {
            id: "kt_add_action_form",
            class: "form",
            novalidate: "novalidate"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_38, [
                _createElementVNode("div", _hoisted_39, [
                  _createElementVNode("label", _hoisted_40, _toDisplayString(_ctx.$t("pages.userDetails.participationLink")), 1),
                  _createElementVNode("div", _hoisted_41, [
                    _createElementVNode("div", _hoisted_42, [
                      _createElementVNode("div", _hoisted_43, [
                        _withDirectives(_createElementVNode("input", {
                          class: "form-control form-control-solid",
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.user.userprofile.participationlink) = $event))
                        }, null, 512), [
                          [_vModelText, this.user.userprofile.participationlink]
                        ])
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_44, [
                  _createElementVNode("label", _hoisted_45, _toDisplayString(_ctx.$t("pages.userDetails.softConcept")), 1),
                  _createElementVNode("div", _hoisted_46, [
                    _createElementVNode("div", _hoisted_47, [
                      _createElementVNode("div", _hoisted_48, [
                        _withDirectives(_createElementVNode("input", {
                          class: "form-control form-control-solid",
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((this.user.userprofile.softconcept) = $event))
                        }, null, 512), [
                          [_vModelText, this.user.userprofile.softconcept]
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_49, [
            _createElementVNode("button", {
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateUser(this.user))),
              id: "kt_account_profile_details_submit",
              class: "btn btn-primary"
            }, [
              _createElementVNode("span", _hoisted_50, _toDisplayString(_ctx.$t("general.update")), 1),
              _createElementVNode("span", _hoisted_51, [
                _createTextVNode(_toDisplayString(_ctx.$t("general.pleaseWait")) + " ", 1),
                _cache[3] || (_cache[3] = _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle ms-2" }, null, -1))
              ])
            ])
          ])
        ])
      ])
    ])
  ], 64))
}